import { ScreenType } from '../../../../config';
import { PropertiesSearchFormProps } from '../../../organisms';

type PropertiesSearchFormShowButtonProp = keyof Pick<
  PropertiesSearchFormProps,
  | 'showSortRecommendedBtn'
  | 'showSortPriceBtn'
  | 'showSearchRadiusFilter'
  | 'showBedroomsFilter'
  | 'showPriceFilter'
  | 'showFurnishingFilter'
  | 'showPetFriendlyFilter'
  | 'showZeroDepositFilter'
  | 'showMoreFilter'
  | 'showViewSwitch'
>;
// This object contains rules with regards to showing which button is shown on
// which screen type.
const propertiesSearchFormShowButtonPropsRules: Record<
  ScreenType,
  Array<PropertiesSearchFormShowButtonProp>
> = {
  [ScreenType.MOBILE]: [
    'showSortRecommendedBtn',
    'showSortPriceBtn',
    'showViewSwitch',
    'showMoreFilter',
  ],
  [ScreenType.TABLET_PORTRAIT]: [
    'showSortRecommendedBtn',
    'showSortPriceBtn',
    'showSearchRadiusFilter',
    'showViewSwitch',
    'showMoreFilter',
  ],
  [ScreenType.TABLET_LANDSCAPE]: [
    'showSearchRadiusFilter',
    'showBedroomsFilter',
    'showPriceFilter',
    'showFurnishingFilter',
    'showPetFriendlyFilter',
    'showZeroDepositFilter',
    'showMoreFilter',
  ],
  [ScreenType.DESKTOP]: [
    'showSearchRadiusFilter',
    'showBedroomsFilter',
    'showPriceFilter',
    'showFurnishingFilter',
    'showPetFriendlyFilter',
    'showZeroDepositFilter',
    'showMoreFilter',
  ],
};
// This creates a key-value object, with each key being a "showButtonX" prop
// name, and each value being true. The intention is to spread the returned
// object into <PropertyListPage />.
export default function getSearchBarButtonsToShow(screenType: ScreenType) {
  return Object.values(
    propertiesSearchFormShowButtonPropsRules[screenType]
  ).reduce<Partial<Record<PropertiesSearchFormShowButtonProp, true>>>(
    (acc, cur) => {
      acc[cur] = true;
      return acc;
    },
    {}
  );
}
