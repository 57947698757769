import * as React from 'react';
import { LatLngLiteral } from '../../api/googleMaps/gcpMaps/types';
import { GoogleMapsPolygon } from './types';

export interface DrawPolygonConfig {
  paths: LatLngLiteral[][];

  strokeColor?: string;
  strokeOpacity?: number;
  strokeWeight?: number;
  fillColor?: string;
  fillOpacity?: number;
}

const defaultPolygonConfig: Partial<DrawPolygonConfig> = {
  strokeColor: '#ed1c24',
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: '#ed1c24',
  fillOpacity: 0.35,
};

export default function drawPolygon(
  map,
  maps,
  polygonRef: React.MutableRefObject<GoogleMapsPolygon[]>,
  config: DrawPolygonConfig
) {
  // Create a new polygon
  polygonRef.current.push(
    new maps.Polygon({
      ...defaultPolygonConfig,
      ...config,
    })
  );

  // Draw the polygon on the map
  polygonRef.current[polygonRef.current.length - 1].setMap(map);
}
