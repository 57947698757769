import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    pin: {
      display: 'inline-block',
      position: 'relative',
      padding: '3px .5rem 3px',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: '2px',
      borderColor: theme.palette.primary.dark,
      backgroundColor: '#fff',
      zIndex: 998,
      cursor: 'pointer',
      '&:hover': {
        borderColor: theme.palette.primary.dark,
        backgroundColor: theme.palette.primary.dark,
        zIndex: 999,
        '& p': {
          color: '#fff',
        },
      },
      '&::after': {
        display: 'block',
        position: 'absolute',
        bottom: -6,
        left: '50%',
        width: '.625rem',
        height: '.625rem',
        transform: 'translateX(-50%) rotate(45deg)',
        border: 'inherit',
        borderWidth: '0 1px 1px 0',
        background: 'inherit',
        content: "''",
      },
      '& p': {
        color: theme.palette.primary.dark,
        fontSize: '.875rem',
        lineHeight: '1rem',
        fontWeight: 600,
      },
    },

    pinSelected: {
      borderColor: theme.palette.primary.dark,
      backgroundColor: theme.palette.primary.dark,
      zIndex: 999,
      '& p': {
        color: '#fff',
      },
    },

    popup: {
      position: 'absolute',
      top: 30,
      width: '12.5rem',
      height: 'auto',
      transform: 'translate(-50%,calc(-100% - 40px))',
      border: '.0625rem solid #c8c9cb',
      borderRadius: 2,
      backgroundColor: '#fff',
      left: 30,
      zIndex: 1000,
      cursor: 'pointer',
      '& img': {
        width: '100%',
        height: '8rem',
        objectFit: 'cover',
      },
      '& p': {
        fontSize: '0.75rem',
        lineHeight: 1,
        color: '#4c4c4c',
        fontWeight: 600,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        paddingBottom: '.5rem',
      },
      '& svg': {
        display: 'flex',
        marginLeft: 'auto',
        marginRight: 'auto',
        color: '#8e8e8e',
        fontSize: 25,
        cursor: 'pointer',
        marginBottom: -5,
      },
    },

    infoWrapper: {
      display: 'flex',
      flexDirection: 'column',
      padding: `.5rem .75rem 0`,
    },

    infoWrapperNormalTextSpan: {
      fontSize: '0.75rem',
    },

    infoWrapperPriceSpan: {
      color: theme.palette.primary.dark,
      fontWeight: 600,
      fontSize: '1rem',
      margin: '0 0.5rem',
    },
  })
);
