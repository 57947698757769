import * as React from 'react';
import GoogleMapReact from 'google-map-react';
import { PROPERTIES_MAP_MAP_CONFIG } from '../../../../config/map';
import drawPolygon from '../../../../utils/map/drawPolygon';
import removePolygon from '../../../../utils/map/removePolygon';
import { LatLngLiteral } from '../../../../api/googleMaps/gcpMaps/types';
import { PropertyV2 } from '../../../../api/property/types';
import PropertyPin from '../PropertyPin/PropertyPin';
import { GoogleMapsPolygonCoordinates } from '../../../../utils/map/types';
import fitMapPolygon from '../../../../utils/map/fitMapPolygon';

export interface PropertiesMapProps {
  properties: PropertyV2[];

  selectedDevelopmentId: number | null;
  setSelectedDevelopmentId: (developmentId: number | null) => void;

  // If this is provided, a polygon will be drawn on the map
  polygonsCoordinates?: GoogleMapsPolygonCoordinates[];
  // Something that allows React to know when to redraw the polygons
  polygonHash?: string;

  // To avoid conflicting with other Google Maps services, the map will only
  // start loading if this flag is true
  shouldLoad: boolean;
}

export default function PropertiesMap({
  properties,
  selectedDevelopmentId,
  setSelectedDevelopmentId,
  polygonsCoordinates,
  polygonHash,
  shouldLoad,
}: PropertiesMapProps) {
  // These refs are used to store the 'map' and 'maps' objects, respectively
  const mapRef = React.useRef();
  const mapsRef = React.useRef();

  const [apiLoaded, setApiLoaded] = React.useState(false);

  // This ref is used to store the polygon. We need to store it so we can clear
  // it later on.
  const polygonRef = React.useRef([]);

  const [mapCenter, setMapCenter] = React.useState<LatLngLiteral>(
    PROPERTIES_MAP_MAP_CONFIG.center
  );

  React.useEffect(() => {
    // Draw a polygon, if it's provided and Google Maps have been initialized
    if (apiLoaded && mapRef.current && mapsRef.current) {
      if (polygonsCoordinates && polygonsCoordinates.length > 0) {
        if (polygonRef.current.length > 0) {
          removePolygon(polygonRef);
        }

        polygonsCoordinates.forEach((polygonCoordinates) => {
          drawPolygon(mapRef.current, mapsRef.current, polygonRef, {
            paths: polygonCoordinates,
          });
        });

        fitMapPolygon(mapRef.current, mapsRef.current, polygonsCoordinates);
      } else {
        removePolygon(polygonRef);
      }
    }
  }, [apiLoaded, polygonHash]);

  const propertyPins = properties.map((property) => {
    const {
      rent_pcm,
      development_id,
      title,
      geographyJson: { lat, lng },
    } = property;

    return (
      <PropertyPin
        key={`${development_id}`}
        lat={lat}
        lng={lng}
        developmentId={development_id}
        title={title}
        rentPcm={rent_pcm}
        selectedDevelopmentId={selectedDevelopmentId}
        setSelectedDevelopmentId={setSelectedDevelopmentId}
        setMapCenter={setMapCenter}
      />
    );
  });

  const handleApiLoaded = (map, maps) => {
    mapRef.current = map;
    mapsRef.current = maps;

    setApiLoaded(true);
  };

  return shouldLoad ? (
    <GoogleMapReact
      bootstrapURLKeys={{
        key: process.env.GATSBY_GOOGLE_MAPS_API_KEY ?? '',
        libraries: ['places'],
        language: 'en-GB',
        region: 'GB',
      }}
      zoom={PROPERTIES_MAP_MAP_CONFIG.zoom}
      hoverDistance={PROPERTIES_MAP_MAP_CONFIG.hoverDistance}
      center={mapCenter}
      onChange={(value) => setMapCenter(value.center)}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
    >
      {propertyPins}
    </GoogleMapReact>
  ) : null;
}
