export const PROPERTIES_MAP_MAP_CONFIG = {
  zoom: 14,
  hoverDistance: 24,
  center: {
    // London coordinates
    lat: 51.5073509,
    lng: -0.1277583,
  },
};

export const PROPERTY_SINGLE_VIEW_MAP_CONFIG = {
  zoom: 11,
  hoverDistance: 24,
  center: {
    // London coordinates
    lat: 51.5073509,
    lng: -0.1277583,
  },
};
