import * as React from 'react';
import { Box, IconButton, Typography } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import getFetchPropertiesPropsFromUrl from '../../../utils/property/getFetchPropertiesPropsFromUrl';
import SEO from '../../atoms/SEO';
import useProperties from '../../../api/property/useProperties/useProperties';
import useStyles from './MapPage.styles';
import { navigate } from '../../../utils/dom';
import { APP_ROUTES } from '../../../config/app';
import { ConnectedPropertiesSearchForm } from '../../widgets';
import getSearchBarButtonsToShow from '../properties/getSearchBarButtonsToShow/getSearchBarButtonsToShow';
import useScreenType from '../../../utils/dom/useScreenType';
import { PageProps } from 'gatsby';
import PropertiesMap from './PropertiesMap/PropertiesMap';
import dbPolygonToMapPolygonCoordinates from '../../../utils/map/dbPolygonToMapPolygonCoordinates';
import useAutocompleteServices from '../../../api/googleMaps/gcpPlaces/hooks/useAutocompleteServices';
import { GoogleMapsPolygonCoordinates } from '../../../utils/map/types';

export interface MapPageProps {
  location: PageProps['location'];
}

export default function MapPage({ location }: MapPageProps) {
  const fetchPropertiesProps = getFetchPropertiesPropsFromUrl({
    search: location?.search ?? '',
  });

  const classes = useStyles();

  const { autocompleteService, placesService } = useAutocompleteServices({
    googleMapsAttributeElementId: 'google-maps-att-el',
  });

  const autocompleteAndPlacesServiceLoaded =
    !!autocompleteService && !!placesService;

  // We don't use pagination in the map page
  delete fetchPropertiesProps.pagination;

  const {
    data: propertiesData,
    status: propertiesStatus,
    error: propertiesError,
  } = useProperties({
    fetchProps: fetchPropertiesProps,
    queryConfig: {
      enabled: !!fetchPropertiesProps.address,
    },
  });

  const properties = propertiesData?.data ?? [];
  const mapPolygonId = propertiesData?.meta.mapPolygonId;
  const mapPolygonGeography = propertiesData?.meta.mapPolygonGeography;

  let polygonsCoordinates: GoogleMapsPolygonCoordinates[] | undefined;

  if (mapPolygonGeography) {
    const { type, coordinates } = mapPolygonGeography;

    if (type === 'MultiPolygon') {
      polygonsCoordinates = (coordinates as [number, number][][][]).map(
        (coords) => dbPolygonToMapPolygonCoordinates(coords)
      );
    } else {
      polygonsCoordinates = [
        dbPolygonToMapPolygonCoordinates(coordinates as [number, number][][]),
      ];
    }
  }

  // This state is used to re-center the map to the selected property
  const [selectedDevelopmentId, setSelectedDevelopmentId] = React.useState<
    number | null
  >(null);

  const screenType = useScreenType();

  const goBackToPropertiesView = () => {
    void navigate(`${APP_ROUTES.properties}/${location?.search}`);
  };

  const buttonsToShowProps = getSearchBarButtonsToShow(screenType);

  return (
    <>
      <SEO
        title="Properties"
        description="Find build to rent properties in the UK"
      />
      <Box className={classes.pageCtn}>
        {/* CONTROL PANEL */}

        <Box className={classes.controlPanelCtn}>
          <Box className={classes.goBackToPropertiesViewBtnCtn}>
            <IconButton onClick={goBackToPropertiesView} color="primary">
              <ArrowBackIos fontSize="small" />
            </IconButton>
          </Box>
          <Box className={classes.searchBarCtn}>
            <ConnectedPropertiesSearchForm
              autocompleteService={autocompleteService}
              placesService={placesService}
              search={location?.search ?? ''}
              redirectIfSearchAddressIsEmpty
              redirectPathname={APP_ROUTES.map}
              hasSearchButton
              {...buttonsToShowProps}
              showViewSwitch={false}
              noAutoPagination
            />
          </Box>
          <Box className={classes.controlPanelDescription}>
            <Typography>
              {properties && properties.length > 0
                ? `Showing ${properties.length} properties`
                : `We couldn't find anything that match your criteria.`}
            </Typography>
          </Box>
        </Box>

        {/* MAP */}

        <Box className={classes.mapCtn}>
          <PropertiesMap
            shouldLoad={autocompleteAndPlacesServiceLoaded}
            properties={properties}
            selectedDevelopmentId={selectedDevelopmentId}
            setSelectedDevelopmentId={setSelectedDevelopmentId}
            polygonsCoordinates={polygonsCoordinates}
            polygonHash={`${mapPolygonId}`}
          />
        </Box>
      </Box>
    </>
  );
}
