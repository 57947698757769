import { GoogleMapsPolygonCoordinates } from './types';

export default function dbPolygonToMapPolygonCoordinates(
  dbPolygon: [number, number][][]
): GoogleMapsPolygonCoordinates {
  return dbPolygon.map((paths) =>
    paths.map(([lng, lat]) => ({
      lat,
      lng,
    }))
  );
}
