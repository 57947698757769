import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageCtn: {
      display: 'flex',
      flexDirection: 'column',
    },

    controlPanelCtn: {
      display: 'grid',
      grid: 'auto / auto 1fr',
      width: '100%',
      backgroundColor: '#fff',
    },

    goBackToPropertiesViewBtnCtn: {
      display: 'flex',
      alignItems: 'flex-start',
      paddingTop: '1.5rem',
    },

    searchBarCtn: {
      flex: 1,
      padding: theme.spacing(2),
    },

    controlPanelDescription: {
      gridColumn: '1 / 3',
      backgroundColor: '#fff',
      paddingLeft: '.75rem',
      '& p': {
        fontSize: '0.75rem',
      },
    },

    mapCtn: {
      height: 'calc(100vh - 18px - 136px)',
      width: '100%',
    },
  })
);

export default useStyles;
