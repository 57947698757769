import * as React from 'react';
import { GoogleMapsPolygon } from './types';

export default function removePolygon(
  polygonRef: React.MutableRefObject<GoogleMapsPolygon[]>
) {
  // Remove all polygons from the map
  polygonRef.current.forEach((polygon) => {
    polygon.setMap(null);
  });

  // Remove all references to the polygons
  polygonRef.current = [];
}
