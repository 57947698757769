export default function fitMapPolygon(map, maps, polygonsCoordinates) {
  const bounds = new maps.LatLngBounds();
  polygonsCoordinates.forEach((arrayPolygonCoords) => {
    arrayPolygonCoords.forEach((polygonCoords) => {
      polygonCoords.forEach(({ lat, lng }) =>
        bounds.extend(new maps.LatLng(lat, lng))
      );
    });
  });
  map.fitBounds(bounds);
}
