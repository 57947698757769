import * as React from 'react';
import { Box, ClickAwayListener, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { Link } from '../../../atoms';
import useStyles from './PropertyPin.styles';
import { formatMoney } from '../../../../utils/number';
import CloudinaryPicture from '../../../atoms/CloudinaryPicture/CloudinaryPicture';
import useFetchPropertyThumbnail from '../../../../api/property/useFetchPropertyThumbnail';
import { LatLngLiteral } from '../../../../api/googleMaps/gcpMaps/types';

export interface PropertyPinProps {
  // The 'lat' and 'lng' is used to place the PropertyPin on the map by
  // google-map-react. We don't influence this in the component.
  lat: number;
  lng: number;

  developmentId: number;
  title: string;
  rentPcm: number;

  selectedDevelopmentId: number | null;
  setSelectedDevelopmentId: (developmentId: number | null) => void;
  setMapCenter: (latLng: LatLngLiteral) => void;
}

export default function PropertyPin({
  lat,
  lng,
  developmentId,
  title,
  rentPcm,
  selectedDevelopmentId,
  setSelectedDevelopmentId,
  setMapCenter,
}: PropertyPinProps) {
  const isSelected = selectedDevelopmentId === developmentId;

  const classes = useStyles();

  const { data: developmentThumbnail } = useFetchPropertyThumbnail({
    developmentId,
    queryConfig: {
      enabled: isSelected,
      staleTime: Infinity,
    },
  });

  const developmentThumbnailSrc = developmentThumbnail?.data.src_url;

  const selectDevelopment = () => {
    setSelectedDevelopmentId(isSelected ? null : developmentId);
    setMapCenter({ lat, lng });
  };

  const unselectDevelopment = () => {
    setSelectedDevelopmentId(null);
  };

  return (
    <Box>
      {isSelected && (
        <ClickAwayListener onClickAway={unselectDevelopment}>
          <Box className={classes.popup}>
            <Link to={`/properties/${developmentId}`} noColor>
              <Box>
                <Box>
                  {!developmentThumbnailSrc && (
                    <Box ml={1.5} mt={1}>
                      Loading...
                    </Box>
                  )}
                  {developmentThumbnailSrc && (
                    <CloudinaryPicture
                      imgSrc={developmentThumbnailSrc}
                      imgAlt="property image"
                      config={{
                        '0': {
                          width: 200,
                        },
                      }}
                    />
                  )}
                </Box>

                <Box className={classes.infoWrapper}>
                  <Typography>{title}</Typography>
                  <Box>
                    <span className={classes.infoWrapperNormalTextSpan}>
                      From
                    </span>
                    <span className={classes.infoWrapperPriceSpan}>
                      {formatMoney(rentPcm, 'GBP', 0)}
                    </span>
                    <span className={classes.infoWrapperNormalTextSpan}>
                      /month
                    </span>
                  </Box>
                </Box>
              </Box>
            </Link>
          </Box>
        </ClickAwayListener>
      )}
      <Box
        className={clsx(
          classes.pin,
          selectedDevelopmentId === developmentId && classes.pinSelected
        )}
        onClick={selectDevelopment}
      >
        <Typography>{formatMoney(rentPcm, 'GBP', 0)}</Typography>
      </Box>
    </Box>
  );
}
