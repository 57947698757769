import * as React from 'react';
import { PageProps } from 'gatsby';
import Layout from '../components/layout/CommonLayout/Layout';
import MapPage from '../components/pages/map/MapPage';
import useAnalyticsSessionData from '../hooks/useAnalyticsSessionData';

export default function Map({ location }: PageProps) {
  useAnalyticsSessionData();

  return (
    <Layout>
      <MapPage location={location} />
    </Layout>
  );
}
